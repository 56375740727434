












import { Component, Vue } from 'vue-property-decorator';
import { Action, State, Getter } from 'vuex-class';
import Navbar from './Navbar.vue';
import BaseNavDropdown from '@/components/base/BaseNavDropdown.vue';
import { NavLink } from '@/jbi-shared/types/Nav';
import { Profile } from '@/store/modules/profile/profile.state';
import { get as _get } from 'lodash';

@Component({
  components: {
    Navbar,
    BaseNavDropdown
  }
})
export default class AppNavbar extends Vue {
  get VUE_APP_BACKEND_HOSTNAME(): string {
    return process.env.VUE_APP_BACKEND_HOSTNAME || '';
  }
  @Getter('userAttribute/isMissingAttributeValue')
  public isMissingAttributeValue!: boolean;

  @Action('getMe', {
    namespace: 'profile'
  })
  public getMe!: () => void;

  @State('profile', {
    namespace: 'profile'
  })
  public profile!: Profile;

  // TODO: it trigger multiple action, consider refactor it
  public async mounted() {
    await this.getMe();
  }

  get userName(): string {
    const firstName = _get(this.profile, 'firstName', '');
    const lastName = _get(this.profile, 'lastName', '');
    return firstName && lastName ? `${firstName} ${lastName}` : '';
  }

  get userEmail(): string {
    return _get(this.profile, 'email');
  }

  get navLinks(): NavLink[] {
    // @ts-ignore
    const routeFilter = this.$root.getNavLinks().map((res) => {
      const notAllowed: boolean =
        this.isMissingAttributeValue && !res.url?.includes('/logout');
      if (res.to?.name === this.$route.name) {
        return {
          ...res,
          disable: true,
          notAllowed
        };
      }
      return {
        ...res,
        notAllowed
      };
    });
    // @ts-ignore
    return routeFilter || [];
  }
}

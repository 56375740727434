



























import { Component, Vue, Prop } from 'vue-property-decorator';
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import { Route } from 'vue-router';
import BreadCrumbs, { Breadcrumb } from './BreadCrumbs.vue';

@Component({
  components: { AppNavbar, AppFooter, BreadCrumbs }
})
export default class Container extends Vue {
  @Prop({ default: 'JBI' })
  public readonly title!: string;

  @Prop({ default: 'JBI' })
  public readonly description!: string;

  @Prop({ default: 'center' })
  public readonly headerAlign!: string;

  @Prop(Array)
  public readonly breadcrumbs!: Breadcrumb[];

  isLastBreadCrumb(i: number) {
    return i === this.breadcrumbs.length - 1;
  }
}















import { Component, Vue, Prop } from 'vue-property-decorator';
import { get as _get } from 'lodash';
import _VersionJson from '../../public/assets/versions/version.json';

interface Version {
  version: {
    release: number | string;
    build: number;
  };
}

const version = _VersionJson ? (_VersionJson as Version).version : undefined;

@Component({})
export default class AppFooter extends Vue {
  public year = new Date().getFullYear();

  get version() {
    return version;
  }
  get versionReleaseNumber() {
    return version ? version.release : undefined;
  }
  get versionBuildNumber() {
    return version ? version.build : undefined;
  }
}

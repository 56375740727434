


































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

@Component({
  components: {}
})
export default class Navbar extends Vue {
  @Getter('rbac/isAdmin') public isAdmin!: boolean;
  @Getter('userAttribute/isMissingAttributeValue')
  public isMissingAttributeValue!: boolean;

  get hasAccessToJournals() {
    return isUserAllowed(PermissionsMatrixActionsEnum.READ, 'journals');
  }
}

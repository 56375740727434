



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';

export interface Breadcrumb {
  route?: Partial<Route>;
  text: string;
}

@Component({})
export default class Container extends Vue {
  @Prop(Array)
  public readonly breadcrumbs!: Breadcrumb[];

  isLastBreadCrumb(i: number) {
    return i === this.breadcrumbs.length - 1;
  }
}

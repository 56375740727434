









































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { NavLink } from '../../jbi-shared/types/Nav';
import { get as _get } from 'lodash';

@Component({})
export default class BaseNavDropdown extends Vue {
  @Prop(String) public userName!: string;
  @Prop(String) public userEmail!: string;
  @Prop({ type: Array, required: true })
  public navLinks!: NavLink[];

  get nameInitial(): string {
    return _get(this.userName, 0) || _get(this.userEmail, 0) || 'U';
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown is-hoverable is-right",class:_vm.$style.container},[_c('b-dropdown',{staticClass:"is-right",attrs:{"hoverable":"","aria-role":"list"}},[_c('b-button',{class:_vm.$style.navDropdownTrigger,attrs:{"slot":"trigger","type":"is-info","size":"is-small"},slot:"trigger"},[_vm._v(_vm._s(_vm.nameInitial.toUpperCase()))]),(_vm.userName && _vm.userEmail)?_c('div',{class:_vm.$style.userInfo},[_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(_vm._s(_vm.userName))]),_c('p',{class:_vm.$style.userInfoSecondary},[_vm._v(_vm._s(_vm.userEmail))])]):(_vm.userName || _vm.userEmail)?_c('div',{class:_vm.$style.userInfo},[_c('p',{class:_vm.$style.userInfoPrimary},[_vm._v(_vm._s(_vm.userName || _vm.userEmail))])]):_vm._e(),_vm._l((_vm.navLinks),function(link,i){return [(!link.disable)?_c('div',{key:i + '' + link.length,class:i === 0 ? '' : _vm.$style.navLinkSection},[(link.to)?_c('router-link',{key:link.name + link.url,staticClass:"dropdown-item",class:[
            link.class,
            link.notAllowed ? _vm.$style.navItemNotAllowed : ''
          ],attrs:{"aria-role":"listitem","to":link.to},on:{"click":function($event){link.onClick ? link.onClick() : undefined}}},[_vm._v(_vm._s(link.label))]):_c('a',{key:link.name + link.url,staticClass:"dropdown-item",class:[
            link.class,
            link.notAllowed ? _vm.$style.navItemNotAllowed : ''
          ],attrs:{"aria-role":"listitem","href":link.url || false},on:{"click":function($event){link.onClick ? link.onClick() : undefined}}},[_vm._v(_vm._s(link.label))])],1):_c('div',{key:i + '' + link.length,class:i === 0 ? '' : _vm.$style.navLinkSection},[_c('a',{key:link.name + link.url,staticClass:"dropdown-item",class:[
            link.class,
            link.notAllowed ? _vm.$style.navItemNotAllowed : ''
          ],attrs:{"aria-role":"listitem"}},[_vm._v(_vm._s(link.label))])])]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }